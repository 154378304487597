<template>
  <v-app>
    <!-- Reminder  dialog -->
    <v-dialog v-model="dialog" max-width="650">
      <v-card>
        <v-card-title class="headline">
          <v-icon>mdi-bell</v-icon>Send a Reminder
        </v-card-title>
        <v-card-text class="mt-4">
          <div class="p-4 border">
            <p class="mb-4">Dear |FIRSTNAME| |SURNAME|,</p>
            <p>
              This is notify you that you health plan on the OpenHISA platform,
              run by the Plateau State government is expiring in 2 weeks. You
              are required to renew your plan within the next two weeks or your
              health insurance plan would come to an end.
            </p>
            <p class="blue--text lighten-1">Please click to renew your plan</p>
            <p>
              Contact our agent on
              <span class="blue--text lighten-1"
                >+2348023459876 info@plateaugovt.com</span
              >
            </p>
          </div>
          <v-row>
            <v-col cols="4" class="p-0">
              <v-checkbox
                color="green darken-4"
                dense
                class="ml-4"
              >
              <template v-slot:label>
                <small class="mt-2">Schedule for later</small>
              </template>
              </v-checkbox>
            </v-col>
            <v-col cols="4">
              <v-text-field
                disabled
                label="DD/MM/YYYY"
                outlined
                small
                dense
                color="green darken-4"
                class="d-inline-block mt-2 m-0 p-0"
                append-icon="mdi-chevron-down"
              ></v-text-field>
            </v-col>
            <v-col class="col-12 py-0 text-right">
              <v-btn
                color="green darken-4"
                class="white--text mb-2"
                medium
                @click.stop="
                  dialog = false;
                  sent = true;
                "
                >Send Reminder</v-btn
              >
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- Page content -->
    <v-row justify="center">
      <v-col cols="11">
        <v-row>
          <v-col cols="11" class="mx-auto">
            <p class="h4">Administrative View</p>
            <v-text-field
              label="Search for any category"
              outlined
              dense
              append-icon="search"
              color="green darken-4"
              class="d-inline-block"
              style="width: 400px"
            ></v-text-field>
            <v-select
              class="d-inline-block ml-4"
              style="width: 150px"
              color="green darken-4"
              :items="filters"
              v-model="sortBy"
              label="Sort By"
              checked
              small
              outlined
              dense
            ></v-select>
          </v-col>
          <v-col cols="11" class="mx-auto">
            <v-btn
              text
              small
              class="font-weight-bold d-block mt-n6 grey lighten-2"
              @click="showAdvancedFilter"
              >{{
                toggle ? "Advanced Search - Close" : "Advanced Search "
              }}</v-btn
            >
          </v-col>
          <v-col cols="11" class="mx-auto" v-show="toggle">
            <v-row>
              <v-col
                cols="11"
                class="d-flex justify-content-start align-items-start"
              >
                <div class="col-2 px-0">
                  <small class="font-weight-bold m-0">
                    Search by expiration date
                  </small>
                  <v-text-field
                    class="d-block"
                    color="green darken-4"
                    placeholder="DD/MM/YYYY"
                    v-model="searchDate"
                    outlined
                    dense
                    append-icon="mdi-chevron-down"
                  ></v-text-field>
                </div>
                <div class="col py-0">
                  <v-radio-group
                    v-model="radios"
                    :mandatory="false"
                    dense
                    row
                    class="flex-wrap"
                  >
                    <!-- exactly 2 weeks -->
                    <v-radio value="Expiring in 2 Weeks" color="green darken-4">
                      <template v-slot:label>
                        <small class="mt-2">Exactly 2 weeks</small>
                      </template>
                    </v-radio>
                    <v-radio value="Already Expired" color="green darken-4">
                      <template v-slot:label>
                        <small class="mt-2">Expired</small>
                      </template>
                    </v-radio>
                    <v-radio
                      value="Expiring in 3 Months"
                      color="green darken-4"
                    >
                      <template v-slot:label>
                        <small class="mt-2">Exactly 3 months</small>
                      </template>
                    </v-radio>
                    <v-radio value="All" color="green darken-4">
                      <template v-slot:label>
                        <small class="mt-2">All</small>
                      </template>
                    </v-radio>
                  </v-radio-group>
                </div>
                <div class="col-6 px-0 d-flex">
                  <div class="d-inline-block">
                    <small class="font-weight-bold m-0 my-1">
                      Search for eligibility
                    </small>
                    <v-text-field
                      class="d-block mr-4"
                      color="green darken-4"
                      placeholder="Enter name or ID"
                      v-model="searchNameID"
                      outlined
                      dense
                      append-icon="mdi-chevron-down"
                    ></v-text-field>
                  </div>
                  <div class="d-inline-block">
                    <small class="font-weight-bold m-0 my-1"
                      >Search by Facility</small
                    >
                    <v-text-field
                      class="d-block"
                      color="green darken-4"
                      placeholder="Select category"
                      v-model="searchFacility"
                      outlined
                      dense
                      append-icon="mdi-chevron-down"
                    ></v-text-field>
                  </div>
                </div>
              </v-col>
            </v-row>
          </v-col>
          <!-- table section -->
          <v-col cols="11" class="mx-auto mb-12">
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th v-show="radios !== ''"></th>
                    <th
                      class="text-left"
                      v-for="(title, index) in titles"
                      :key="index"
                    >
                      {{ title }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <!-- show reminder -->
                  <tr v-show="radios !== ''">
                    <td colspan="9">
                      <v-row>
                        <v-col cols="12">
                          <p class="font-weight-bold">
                            <v-icon small>mdi-bell</v-icon>
                            Set a reminder: Policies {{ radios }}
                          </p>
                        </v-col>
                        <!-- success message -->
                        <v-col v-if="sent">
                          <p>
                            Reminder sent to enrollee
                            <v-icon medium color="green"
                              >mdi-check-circle-outline</v-icon
                            >
                          </p>
                        </v-col>
                        <!-- notification checkboxes -->
                        <v-col v-if="sent === false" md="4">
                          <span>Send notifications alert to</span>
                          <v-row class="mt-0">
                            <v-checkbox
                              v-model="recipients.enrollee"
                              color="green darken-4"
                              class="ml-4 mt-0"
                            >
                              <template v-slot:label>
                                <small class="mt-2">Enrollee</small>
                              </template>
                            </v-checkbox>
                            <v-checkbox
                              v-model="recipients.admin"
                              color="green darken-4"
                              class="ml-2 mt-0"
                            >
                              <template v-slot:label>
                                <small class="mt-2">Admin</small>
                              </template>
                            </v-checkbox>
                          </v-row>
                        </v-col>
                        <v-col v-if="sent === false" class="px-0 ml-n4">
                          <v-row>
                            <div class="d-inline-block mr-5 shrink p-0 col-4">
                              <p class="font-weight-bold m-0 my-1">Frequency</p>
                              <v-text-field
                                placeholder="Once every week"
                                outlined
                                readonly
                                dense
                                color="green darken-4"
                                append-icon="mdi-chevron-down"
                              ></v-text-field>
                            </div>
                            <v-radio-group
                              :mandatory="false"
                              row
                              class="mt-5 pt-2"
                            >
                              <v-radio value="email" color="green darken-4">
                                <template v-slot:label>
                                  <small class="mt-2">Via Email</small>
                                </template>
                              </v-radio>
                              <v-radio value="sms" color="green darken-4">
                                <template v-slot:label>
                                  <small class="mt-2">Via SMS</small>
                                </template>
                              </v-radio>
                            </v-radio-group>
                            <v-btn
                              color="green darken-4"
                              class="white--text mt-6 ml-12"
                              :disabled="enrollees.length === 0"
                              medium
                              @click.stop="
                                dialog = true;
                                selectedEnrollees = [];
                              "
                              >Send Reminder</v-btn
                            >
                          </v-row>
                        </v-col>
                      </v-row>
                    </td>
                  </tr>
                  <!-- shows table if query matches -->
                  <tr
                    v-show="filteredEnrollees.length !== 0"
                    v-for="enrollee in filteredEnrollees"
                    :key="enrollee.id"
                  >
                    <td v-show="radios !== ''">
                      <v-checkbox
                        type="checkbox"
                        :value="enrollee.id"
                        v-model="selectedEnrollees"
                        dense
                        color="green darken-4"
                      ></v-checkbox>
                    </td>
                    <td>{{ enrollee.first_name }} {{ enrollee.last_name }}</td>
                    <td>
                      {{
                        calculateAge(JSON.parse(enrollee.json)["date_of_birth"])
                      }}
                    </td>
                    <td>{{ enrollee.unique_id }}</td>
                    <td></td>
                    <td>
                      {{
                        new Date(enrollee.date_registered).toLocaleDateString()
                      }}
                    </td>
                    <td>
                      {{
                        calculateExpirationDate(
                          new Date(enrollee.date_registered)
                        )
                      }}
                    </td>
                    <td>{{ JSON.parse(enrollee.json)["primary_hcp"] }}</td>
                    <td>{{ JSON.parse(enrollee.json)["lga"] }}</td>
                  </tr>
                  <!-- shows when no query is found for search term -->
                  <tr v-if="filteredEnrollees.length === 0">
                    <td>No results found for your search.</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-app>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "Retention",
  async created() {
    // populate vuex state with active enrollment list
    await this.fetchActiveEnrollees();
  },
  data() {
    return {
      sortBy: [],
      toggle: false,
      dialog: false,
      sent: false,
      searchNameID: "",
      searchFacility: "",
      searchDate: "",
      radios: "",
      recipients: {
        enrollee: "",
        admin: "",
      },
      titles: [
        "Patient Name",
        "Age",
        "Identification Number",
        "Dependants",
        "Date Issued",
        "Expiry Date",
        "Facility",
        "LGA",
      ],
      filters: [
        "Name",
        "Age",
        "ID Number",
        "Dependants",
        "Date Issued",
        "Expiry Date",
        "Facility",
        "LGA",
      ],
      selectedEnrollees: [],
    };
  },
  computed: {
    ...mapGetters({ enrollees: "enrollmentModule/enrollees" }),
    filteredEnrollees() {
      if (this.searchNameID !== "") {
        const regex = new RegExp(this.searchNameID, "i");
        return this.enrollees.filter(
          (e) => regex.test(e.first_name) || regex.test(e.last_name) || regex.test(e.unique_id)
        );
      } else if (this.searchFacility !== "") {
        const regex = new RegExp(this.searchFacility, "i");
        return this.enrollees.filter((e) =>
          regex.test(JSON.parse(e.json)["primary_hcp"])
        );
      } else if (this.searchDate !== "") {
        const regex = new RegExp(this.searchDate, "i");
        return this.enrollees.filter((e) =>
          regex.test(this.calculateExpirationDate(new Date(e.date_registered)))
        );
      } else {
        return this.enrollees;
      }
    },
  },
  methods: {
    showAdvancedFilter() {
      this.toggle = !this.toggle;
    },
    calculateExpirationDate(date) {
      const expiringDate = new Date(
        date.getFullYear() + 1,
        date.getMonth(),
        date.getDate()
      );
      return expiringDate.toLocaleDateString();
    },
    calculateAge(dateString) {
      return Math.abs(
        new Date(Date.now() - new Date(dateString).getTime()).getUTCFullYear() -
          1970
      );
    },
    ...mapActions({
      fetchActiveEnrollees: "enrollmentModule/fetchActiveEnrollees",
      fetchActiveEnrolleesTwoWeeksPolicies:
        "enrollmentModule/fetchActiveEnrolleesTwoWeeksPolicies",
      fetchActiveEnrolleesThreeMonthsPolicies:
        "enrollmentModule/fetchActiveEnrolleesThreeMonthsPolicies",
      fetchInactiveEnrollees: "enrollmentModule/fetchInactiveEnrollees",
    }),
  },
  watch: {
    radios: function (val) {
      switch (val) {
        case "Expiring in 2 Weeks":
          this.fetchActiveEnrolleesTwoWeeksPolicies();
          break;
        case "Already Expired":
          this.fetchInactiveEnrollees();
          break;
        case "Expiring in 3 Months":
          this.fetchActiveEnrolleesThreeMonthsPolicies();
          break;
        default:
          this.fetchActiveEnrollees();
          break;
      }
    },
  },
};
</script>

<style scoped>
.v-dialog__content{
  margin-top: 5vh;
}
</style>
